var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "my-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('div', {
    staticClass: "bold-text font-size-h3"
  }, [_vm._v("TestApp.io API")]), _c('div', [_vm._v("Integrate with TestApp.io API")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', {
    staticClass: "bold-text font-size-h3"
  }, [_vm._v("Credentials")])]), _c('v-card-text', [_c('v-row', {
    staticClass: "bodyFont"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Ta-cli is a command line tool which will allow you to upload your android and ios file locally or integrate with CI/CD. To be able to use this service, you must obtain unique identifier (API key). API key are generated by combining individual user with associated team. ")]), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" These keys can be used to authenticate with the API as well as using in local device to upload file locally. ")])])], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "font-weight-bold mx-2 text-transform-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'api-tokens'
        });
      }
    }
  }, [_vm._v(" Manage API Credentials")])], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-card-title', [_c('div', {
    staticClass: "bold-text font-size-h3"
  }, [_vm._v("Installing")])]), _c('v-card-text', [_c('v-row', {
    staticClass: "bodyFont"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Commands are run from the command line, in the root directory of ta-cli. There are two options for downloading and running ta-cli globally. ")]), _c('div', {
    staticClass: "bold-text mt-4"
  }, [_vm._v("Install with Brew")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.brewCode
    }
  }), _c('div', {
    staticClass: "mt-4 bold-text"
  }, [_vm._v("Install with a link")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.linkCode
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('div', [_vm._v(" To confirm your install you can run "), _c('code', [_vm._v("--version")]), _vm._v(" to see current version ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.versionCode
    }
  }), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" If a ta-cli is successfully installed then you should see the following result ")]), _c('div', [_c('v-img', {
    attrs: {
      "height": "400",
      "contain": "",
      "width": "400",
      "src": "/media/tacli/version.png"
    }
  })], 1)], 1)])], 1)], 1)], 1), _c('v-card', {
    staticClass: "my-3"
  }, [_c('v-card-title', [_c('div', {
    staticClass: "bold-text font-size-h3"
  }, [_vm._v("Configuration")])]), _c('v-card-text', [_c('div', {
    staticClass: "bodyFont"
  }, [_c('div', [_vm._v(" After successfully installing a ta-cli. You have two options for using it for deploying. You can either run the command directly in a cli or can make a "), _c('code', [_vm._v("config.json")]), _vm._v(" file. ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.createNewConfig
    }
  }), _c('div', {
    staticClass: "mt-3"
  }, [_vm._v(" Config file contains your "), _c('code', [_vm._v("API token")]), _vm._v(", "), _c('code', [_vm._v("app id")]), _vm._v(" and other information. Copy and paste the following json in "), _c('code', [_vm._v("config.json")])]), _c('code-highlighter', {
    attrs: {
      "code": _vm.tajson
    }
  }), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" You can get you API token from portal account settings page. Use this as a configuration for deployment. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Note: If you are releasing only Android, you don't have to specify the IPA path and vice versa. ")])], 1)])], 1), _c('v-card', [_c('v-card-title', [_c('div', {
    staticClass: "bold-text font-size-h3"
  }, [_vm._v("Running Commands")])]), _c('v-card-text', [_c('div', {
    staticClass: "bodyFont"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" There are different ways of running and deploying your release in the "), _c('code', [_vm._v("ta-cli")]), _vm._v(". If you have created a json file as "), _c('code', [_vm._v("config.json")]), _vm._v(". You can simple run the following command to publish your release. ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.publishjson
    }
  }), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" If all the information in "), _c('code', [_vm._v("config.json")]), _vm._v(" is correct then you can see your file uploading. ")]), _c('div', [_c('v-img', {
    staticClass: "mx-3",
    attrs: {
      "src": "/media/tacli/releasing.png",
      "contain": "",
      "height": "300",
      "width": "500"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_vm._v(" To know about the different options for publish you can run below command. ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.publishHelp
    }
  }), _c('div', [_c('v-img', {
    attrs: {
      "src": "/media/tacli/publishhelp.png",
      "height": "400",
      "width": "500",
      "contain": ""
    }
  })], 1), _c('div', {
    staticClass: "my-4"
  }, [_vm._v(" You can override any config.json variable with this command. ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.overridecode
    }
  }), _c('div', {
    staticClass: "my-4"
  }, [_vm._v(" In case you have not created a config.json file you can run a following command to directly publish your release. ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.publishCode
    }
  }), _c('div', {
    staticClass: "my-4"
  }, [_vm._v(" Other options for publishing release is to from "), _c('code', [_vm._v("config.json")]), _vm._v(" path. ")]), _c('code-highlighter', {
    attrs: {
      "code": _vm.fromConfigPath
    }
  })], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('div', [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-lightbulb-on')
    }
  }), _c('span', {
    staticClass: "bold-text font-size-h3"
  }, [_vm._v(" How do i identify people ")])], 1)]), _c('v-card-text', [_c('div', {
    staticClass: "bodyFont"
  }, [_c('div', [_vm._v(" You can also send data to TestApp.io through your user's web browsers. While Api integration is more flexible, this is a really quick way to get started. ")]), _c('div', {
    staticClass: "my-3"
  }, [_vm._v(" Copy and paste the javascript snippet below into the bottom of your site. ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }