var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-breadcrumbs', {
    staticClass: "text-body-1 mt-4 pa-2",
    attrs: {
      "items": _vm.breadCrumbs
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(props) {
        return [_c('span', {
          class: props.item.disabled ? '' : 'indigo--text text--accent-2 pointer',
          domProps: {
            "textContent": _vm._s(props.item.text)
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(props.item.to);
            }
          }
        })];
      }
    }])
  }, [_c('v-icon', {
    attrs: {
      "slot": "divider"
    },
    slot: "divider"
  }, [_vm._v("mdi-chevron-right")])], 1), _c('test-app-io')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }