<template>
  <div>
    <v-breadcrumbs :items="breadCrumbs" class="text-body-1 mt-4 pa-2">
      <template slot="item" slot-scope="props">
        <span
          :class="
            props.item.disabled ? '' : 'indigo--text text--accent-2 pointer'
          "
          @click="$router.push(props.item.to)"
          v-text="props.item.text"
        ></span>
      </template>
      <v-icon slot="divider">mdi-chevron-right</v-icon>
    </v-breadcrumbs>
    <test-app-io></test-app-io>
  </div>
</template>

<script>
import TestAppIo from "@/view/components/Team/TestAppIo.vue";
export default {
  components: { TestAppIo },
  data() {
    return {
      breadCrumbs: [
        { text: "Team", disabled: false, to: { name: "team-members" } },
        {
          text: "Integrations",
          disabled: false,
          to: { name: "integrations" },
        },
        {
          text: "testappio",
          disabled: true,
        },
      ],
    };
  },
};
</script>
