<template>
  <v-row class="my-4">
    <v-col cols="12">
      <div>
        <div class="bold-text font-size-h3">TestApp.io API</div>
        <div>Integrate with TestApp.io API</div>
      </div>
    </v-col>
    <v-col cols="12" md="9">
      <v-card outlined>
        <v-card-title>
          <div class="bold-text font-size-h3">Credentials</div>
        </v-card-title>
        <v-card-text>
          <v-row class="bodyFont">
            <v-col cols="12">
              <div>
                Ta-cli is a command line tool which will allow you to upload
                your android and ios file locally or integrate with CI/CD. To be
                able to use this service, you must obtain unique identifier (API
                key). API key are generated by combining individual user with
                associated team.
              </div>
              <div class="my-3">
                These keys can be used to authenticate with the API as well as
                using in local device to upload file locally.
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="font-weight-bold mx-2 text-transform-none"
            @click="$router.push({ name: 'api-tokens' })"
          >
            Manage API Credentials</v-btn
          >
        </v-card-actions>
        <v-divider class="my-3"></v-divider>
        <v-card-title>
          <div class="bold-text font-size-h3">Installing</div>
        </v-card-title>
        <v-card-text>
          <v-row class="bodyFont">
            <v-col cols="12">
              <div>
                Commands are run from the command line, in the root directory of
                ta-cli. There are two options for downloading and running ta-cli
                globally.
              </div>
              <div class="bold-text mt-4">Install with Brew</div>
              <code-highlighter :code="brewCode"></code-highlighter>
              <div class="mt-4 bold-text">Install with a link</div>
              <code-highlighter :code="linkCode"></code-highlighter>
            </v-col>
            <v-col cols="12">
              <div>
                <div>
                  To confirm your install you can run <code>--version</code> to
                  see current version
                </div>
                <code-highlighter :code="versionCode"></code-highlighter>
                <div class="my-3">
                  If a ta-cli is successfully installed then you should see the
                  following result
                </div>
                <div>
                  <v-img
                    height="400"
                    contain
                    width="400"
                    src="/media/tacli/version.png"
                  ></v-img>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-3">
        <v-card-title>
          <div class="bold-text font-size-h3">Configuration</div>
        </v-card-title>
        <v-card-text>
          <div class="bodyFont">
            <div>
              After successfully installing a ta-cli. You have two options for
              using it for deploying. You can either run the command directly in
              a cli or can make a <code>config.json</code> file.
            </div>
            <code-highlighter :code="createNewConfig"></code-highlighter>
            <div class="mt-3">
              Config file contains your <code>API token</code>,
              <code>app id</code> and other information. Copy and paste the
              following json in <code>config.json</code>
            </div>
            <code-highlighter :code="tajson"></code-highlighter>
            <div class="my-3">
              You can get you API token from portal account settings page. Use
              this as a configuration for deployment.
            </div>
            <div class="mb-3">
              Note: If you are releasing only Android, you don't have to specify
              the IPA path and vice versa.
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
          <div class="bold-text font-size-h3">Running Commands</div>
        </v-card-title>
        <v-card-text>
          <div class="bodyFont">
            <div class="mb-3">
              There are different ways of running and deploying your release in
              the <code>ta-cli</code>. If you have created a json file as
              <code>config.json</code>. You can simple run the following command
              to publish your release.
            </div>
            <code-highlighter :code="publishjson"></code-highlighter>
            <div class="my-3">
              If all the information in <code>config.json</code> is correct then
              you can see your file uploading.
            </div>
            <div>
              <v-img
                class="mx-3"
                src="/media/tacli/releasing.png"
                contain
                height="300"
                width="500"
              ></v-img>
            </div>
            <div class="mt-3">
              To know about the different options for publish you can run below
              command.
            </div>
            <code-highlighter :code="publishHelp"></code-highlighter>
            <div>
              <v-img
                src="/media/tacli/publishhelp.png"
                height="400"
                width="500"
                contain
              ></v-img>
            </div>
            <div class="my-4">
              You can override any config.json variable with this command.
            </div>
            <code-highlighter :code="overridecode"></code-highlighter>
            <div class="my-4">
              In case you have not created a config.json file you can run a
              following command to directly publish your release.
            </div>
            <code-highlighter :code="publishCode"></code-highlighter>
            <div class="my-4">
              Other options for publishing release is to from
              <code>config.json</code> path.
            </div>
            <code-highlighter :code="fromConfigPath"></code-highlighter>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="3">
      <v-card outlined>
        <v-card-title>
          <div>
            <v-icon left v-text="'mdi-lightbulb-on'"></v-icon>
            <span class="bold-text font-size-h3">
              How do i identify people
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="bodyFont">
            <div>
              You can also send data to TestApp.io through your user's web
              browsers. While Api integration is more flexible, this is a really
              quick way to get started.
            </div>
            <div class="my-3">
              Copy and paste the javascript snippet below into the bottom of
              your site.
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// import CodeHighlighter from "@/view/components/Common/CodeHighlighter.vue";

export default {
  // components: {
  //   CodeHighlighter,
  // },
  data() {
    return {
      versionCode: `ta-cli --version`,
      brewCode: `brew ta-cli install`,
      publishHelp: `ta-cli publish -h`,
      fromConfigPath: `publish --config=/path/to/ta.json`,
      createNewConfig: `touch config.json`,
      publishCode: `ta-cli publish --token=[Your API Token] --app_id=[App you want to release to] --notify=[yes or no] --release=[both or android or ios] --apk=[/path/to/app.apk] --ipa=[/path/to/app.ipa]`,
      overridecode: `ta-cli publish --config=/path/to/config.json\\n ta-cli publish --config=/path/to/config.json --release=android | override the config`,
      linkCode: `curl -Ls https://github.com/ipinfo/cli/releases/download/ipinfo-1.1.3/macos.sh | sh`,
      publishjson: `ta-cli publish`,
      tajson: `{
            "apiToken": "Your API Token", //get it from /settings/api-tokens
            "appID": "Your App ID", //get it from app page
            "release": "both", //both, android, ios
            "apk": "/path/to/file.apk",
            "ipa": "/path/to/file.ipa",
            "notify": "yes"
       }`,
      code: `
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
        h._hjSettings = {
          hjid: 2362721,
          hjsv: 6
        };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    `,
    };
  },
  methods: {},
};
</script>
